<template>
  <!--begin::Part Listing-->
  <div class="product-template">
    <PageHeaderCount
      moduleType="door-type"
      :dataLoading="dataLoading"
      :statusList="statusList"
      :extraList="topExtraList"
      allkey="all_doortypes"
      countkey="doortype_status_count"
    ></PageHeaderCount>
    <v-col
      v-if="alphabets.length"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable">
        <v-btn
          :disabled="searching"
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >
        <v-btn
          :disabled="searching"
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge>
        </v-btn>
      </div>
    </v-col>
    <ListingTemplate
      :customClass="'product-listing'"
      v-if="getPermission('door-type:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('door-type:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="partBulkAction(item.status)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0">
                <v-layout class="w-100">
                  <v-flex class="mxw-177">
                    <h1 class="form-title d-flex margin-auto">
                      <v-select
                        :disabled="dataLoading"
                        :items="statusList"
                        v-model="status"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                            <!--  <v-list-item-subtitle
                              v-text="item.description"
                            ></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              :color="item.color"
                              :text-color="item.textcolor"
                            >
                              <template v-if="item.value == 'all'">{{
                                item.all_doortypes
                              }}</template>
                              <template v-else>{{
                                item.doortype_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('door-type:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    v-on:click="openCreateDialog()"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <template v-if="getPermission('door-type:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  v-on:click="exportQuotation"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-database-export</v-icon> Export
                </v-btn>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'product',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          v-on:change="updateVisible()"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="product"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="door-type"
          :basicSearchFields="['title', 'barcode']"
          :allowedSearchFields="searchableArray"
        >
        </ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    class="product-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="`max-width: ${cols.width}px`"
                        v-on:click="detailRow(cols.checkbox, data)"
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <v-img
                            class="customer-image"
                            width="70"
                            height="70"
                            contain
                            :lazy-src="$defaultImage"
                            :src="$assetAPIURL(data.part_image)"
                          >
                          </v-img>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'title'">
                          <span class="font-weight-600">{{ data.title }}</span>
                        </template>
                        <template v-else-if="cols.field == 'description'">
                          <template v-if="data?.description">
                            <read-more
                              class="custom-read-more font-weight-600 font-size-18 pb-1"
                              more-str="read more"
                              :text="data?.description"
                              link="#"
                              less-str="read less"
                              :max-chars="120"
                            >
                            </read-more>
                          </template>

                          <em v-else class="text-muted"> no description</em>
                        </template>
                        <!--  <template v-else-if="cols.field == 'description'">
                                                     <div v-on:keypress="(e) => preventDefault(e)">
                                                        <read-more
                                                            class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                                                            more-str="read more"
                                                            :text="data.description || '<em>No Description</em>'"
                                                            link="#"
                                                            less-str="read less"
                                                            :max-chars="100"
                                                            >
                                                            </read-more>
                                                     </div>
                                                </template> -->
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatedateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no door type at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Door Type</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field == 'is_low_stock'">
                      <v-checkbox
                        class="mt-0 width-100"
                        color="cyan"
                        v-model.trim="listingSearch.is_low_stock"
                        @change="filterRows"
                      >
                        <template v-slot:label>
                          <label class="custom-form-label m-0 width-100"
                            >Low Stock</label
                          >
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col md="6" :key="index" v-else-if="field != 'dates'">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >{{ getFilterLabel(field, "Door Type") }}</label
                      >
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Door Type')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <template v-if="getPermission('door-type:create')">
          <Dialog :common-dialog="door_type_dialog" :dialog-width="dialogWidth">
            <template v-slot:title>
              <span v-if="doorTypeCreate.id">Update Door Type</span>
              <span v-else>Create Door Type</span>
            </template>
            <template v-slot:body>
              <v-layout class="align-center pt-2 pr-3 pl-4">
                <v-flex md3>
                  <label class="font-weight-700 font-size-16 width-100 required"
                    >Door Type Title</label
                  >
                </v-flex>
                <v-flex md9>
                  <TextField
                    id="door-type-label"
                    dense
                    filled
                    placeholder="Door Type Title"
                    solo
                    flat
                    counter="50"
                    v-model.trim="doorTypeCreate.title"
                    color="cyan"
                    :maxlength="250"
                    :rules="[
                      validateRules.required(
                        doorTypeCreate.title,
                        'Door Type Title'
                      ),
                    ]"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="pt-2 pr-3 pl-4">
                <v-flex md3 class="pt-5">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Door Type Description</label
                  >
                </v-flex>
                <v-flex md9 class="pt-5">
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model.trim="doorTypeCreate.description"
                    placeholder="Door Type Description"
                    solo
                    flat
                    row-height="25"
                    counter="250"
                  />
                </v-flex>
              </v-layout>
            </template>
            <template v-slot:action>
              <v-btn
                :loading="door_loading"
                :disabled="door_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="saveDoorType()"
              >
                Save
              </v-btn>
              <v-btn
                :disabled="door_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="closeCreateDialog()"
              >
                Cancel
              </v-btn>
            </template>
          </Dialog>
        </template>
        <template v-if="getPermission('door-type:view')">
          <Dialog
            :common-dialog="edit_detail_dialog"
            :dialog-width="dialogWidth"
          >
            <template v-slot:title>
              <v-layout>
                <v-flex> Door Type </v-flex>
                <v-flex class="text-right">
                  <!-- <v-btn :disabled="updateLoading" :loading="updateLoading"
                                        class="mx-2 custom-bold-button" :color="doorTypeDetail.activated
                                                ? 'red lighten-1 white--text'
                                                : 'cyan white--text'
                                            " v-on:click="updateStatus()">Mark as
                                        {{ doorTypeDetail.activated ? "Inactive" : "Active" }}</v-btn> -->
                  <v-btn
                    :disabled="updateLoading"
                    :loading="updateLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                    v-on:click="updateRow()"
                    >Edit</v-btn
                  >
                  <v-btn
                    :disabled="updateLoading"
                    :loading="updateLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="edit_detail_dialog = false"
                    >Close</v-btn
                  >
                </v-flex>
              </v-layout>
            </template>
            <template v-slot:body>
              <div>
                <v-tabs
                  v-model="doorTab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent"
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#overview"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/door-type.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Overview
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="doorTab">
                  <v-tab-item value="overview">
                    <div class="px-2 my-4">
                      <table
                        width="100%"
                        style="table-layout: fixed; color: #263035"
                      >
                        <tr>
                          <td
                            class="font-size-18 font-weight-500 py-2"
                            width="20%"
                          >
                            Title
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-2"
                            width="80%"
                          >
                            {{ doorTypeDetail.title }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="font-size-18 font-weight-500 py-2 pt-4"
                            width="20%"
                            valign="top"
                          >
                            Description
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-2"
                            width="80%"
                          >
                            <div style="white-space: pre-line">
                              <template v-if="dataLoading">
                                <v-skeleton-loader
                                  class="custom-skeleton width-80"
                                  type="text"
                                >
                                </v-skeleton-loader>
                              </template>
                              <template v-else>
                                <template v-if="doorTypeDetail?.description">
                                  <read-more
                                    class="custom-read-more font-weight-600 font-size-18 pb-1"
                                    more-str="read more"
                                    :text="doorTypeDetail?.description"
                                    link="#"
                                    less-str="read less"
                                    :max-chars="400"
                                  >
                                  </read-more>
                                </template>

                                <em v-else class="text-muted">
                                  no description</em
                                >
                              </template>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </Dialog>
        </template>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/Gliderol-Door-Type.xlsx"
          api-url="door-type/import"
          title="Import Door Type"
          v-on:reload="getRows()"
          v-on:close="importDialog = false"
        ></ImportTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Product Listing-->
</template>

<script>
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";

import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  DOWNLOAD,
  POST,
  PATCH,
  PUT,
  GET,
} from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import moment from "moment-timezone";
import JwtService from "@/core/services/jwt.service";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [CommonMixin, ListingMixin, ValidationMixin],
  name: "door-type-list",
  data() {
    return {
      door_loading: false,
      exportLoading: false,
      searching: false,
      pageModule: "door-type-listing",
      routeAPI: "door-type",
      doorTab: "overview",
      routeName: "door-type",
      door_type_dialog: false,
      edit_detail_dialog: false,
      doorTypeDetail: {},
      status: "all",
      file: "",
      importDialog: false,
      updateLoading: false,
      statusList: [],
      customThead: [],
      categoryList: [],
      doorTypeCreate: {
        id: null,
        title: null,
        description: null,
        taxable: false,
      },
      allowedSearchFields: ["barcode", "title"],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
      ],
    };
  },
  components: {
    PageHeaderCount,
    draggable,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ImportTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    openCreateDialog() {
      this.door_type_dialog = true;
    },
    closeCreateDialog() {
      this.door_type_dialog = false;
      if (this.doorTypeCreate?.id) {
        this.edit_detail_dialog = true;
      }
    },
    saveDoorType() {
      const _this = this;

      if (!_this.doorTypeCreate.title) {
        return false;
      }

      let requestType = POST;
      let requestURL = "door-type";

      if (_this.doorTypeCreate.id) {
        requestType = PUT;
        requestURL = "door-type/" + _this.doorTypeCreate.id;
      }
      _this.door_loading = true;

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.doorTypeCreate })
        .then((output) => {
          _this.door_type_dialog = false;
          _this.edit_detail_dialog = true;
          _this.doorTypeDetail = {};
          _this.detailRow(false, { ...output.data });
          _this.doorTypeCreate = new Object({
            id: null,
            title: null,
            description: null,
            taxable: false,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
          _this.door_loading = false;
        });
    },
    updateRow() {
      this.edit_detail_dialog = false;
      this.doorTypeCreate = {
        id: this.doorTypeDetail.id,
        title: this.doorTypeDetail.title,
        description: this.doorTypeDetail.description,
        taxable: false,
      };
      this.$nextTick(() => {
        this.door_type_dialog = true;
      });
    },
    detailRow(is_checkbox, row) {
      if (is_checkbox == 1) {
        return false;
      }
      this.doorTab = "overview";
      this.$store
        .dispatch(GET, { url: `door-type/${row.id}` })
        .then(({ data }) => {
          this.doorTypeDetail = data;
          this.edit_detail_dialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    formatedateTime(param) {
      return moment(param).format("hh:mm A");
    },
    partBulkAction(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "door-type",
          data: {
            status,
            doortypes: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    exportQuotation() {
      let downloadURL = process.env.VUE_APP_API_URL + "parts/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }
      });
      window.open(downloadURL, "_blank");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkProductExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("product");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "part/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkProductExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "parts/export",
        })
        .then(({ data }) => {
          saveAs(data, "parts-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Door Types",
      },
    ]);

    if (this.getPermission("door-type:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
